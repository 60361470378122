%title{
  h2{
    padding-left: rem(5);
    margin-bottom: 30px;
    font-size: rem(28);
    color: $black;
    letter-spacing: rem(5);
    line-height: rem(34);
    font-weight: normal;
    & + h4{
      margin-top: -23px;
      margin-bottom: 25px;
      font-size: rem(18);
      color: $main;
      font-weight: normal;
      letter-spacing: rem(2);
      img{
        margin-top: 15px;
        @include size(60px,auto);
      }
    }
  }
}

%pagecontent{
  p{
    font-size: rem(16);
    letter-spacing: rem(2);
    line-height: rem(32);
  }
}