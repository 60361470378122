[data-ks*="fade"] {
  opacity: 0;
  transition-property: opacity,transform;
  transition-duration: 1s;
  &.ks-animated {
    opacity: 1;
  }
}
[data-ks="fadeDown"] {
  transform: translate3d(0,-50px,0);
  &.ks-animated {
    transform: translateZ(0px);
  }
}
[data-ks="fadeUp"] {
  transform: translate3d(0,50px,0);
  &.ks-animated {
    transform: translateZ(0px);
  }
}
[data-ks="fadeLeft"] {
  transform: translate3d(-50px,0,0);
  &.ks-animated {
    transform: translateZ(0px);
  }
}
[data-ks="fadeRight"] {
  transform: translate3d(50px,0,0);
  &.ks-animated {
    transform: translateZ(0px);
  }
}
[data-ks="fadeScale"] {
  transform: translate3d(0,50px,0) scale(0.8);
  &.ks-animated {
    transform: translateZ(0) scale(1);
  }
}