// 左側
.menu-toggle-btn {
  font-size: 0.9rem;
  color: #fff;
  display: none;
  line-height: normal;
  padding: 5px 0px;
  text-align: center;
  position: fixed;
  left: 0;
  top: 40%;
  width: 20px;
  background-color: $btnBg;
  border: none;
  outline: none;
  z-index: 999;
  border-radius: 0 5px 5px 0;
  outline: none !important;
  @include t(0.8);
  @include mobile {
    display: block;
  }
  span:last-of-type {
    display: none;
  }
  &.toggle {
    left: 275px;
    z-index: 999999;
    span {
      display: none;
    }
    span:last-of-type {
      display: block;
    }
  }
}


#secondary {
  padding: 0;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
  @include t(0.8);
  // @include mobile {
  //   position: fixed;
  //   padding: 30px 20px;
  //   background-color: #fff;
  //   height: 100%;
  //   top: 0;
  //   left: -275px;
  //   overflow: auto;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  //   &.in {
  //     left: 0;
  //     z-index: 999999;
  //   }
  // }
  .widget {
    margin-bottom: 30px;
  }
  .widget-title{
    display: none;
  }
  .product-categories,
  .widget_categories > ul {
    margin: 0 0 0 -5px;
    width: calc(100% + 10px);
    padding: 0;
    display: flex;
    justify-content: space-between;
    > li{
      > a{
        margin: 0 5px;
        background-color: rgba($main,0.5);
      }
    }
    li {
      margin: 0;
      list-style-type: none;
      a {
        font-size: 1rem;
        color: #fff;
        display: block;
        padding: 5px 0px;
        text-decoration: none;
        text-align: center;
        @include t(.3);
        &:hover {
          color: $hover;
        }
      }
      &.cat-parent {
        cursor: pointer;
        position: relative;
        & > a {
          @include clearfix;
        }
        & > span {
          font-size: 1rem;
          color: $black;
          display: block;
          padding: 5px 0;
          position: absolute;
          top: 0;
          right: 5px;
          width: 30px;
          text-align: center;
          &:after {
            content: "+";
          }
        }
      }
      &.current-cat,&:hover {
        & > a,
        & > span {
          color: #fff;
          background-color: $main;
        }
      }
    }
  }
  .widget_recently_viewed_products {
    .product_list_widget {
      li {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        a {
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-bottom: -20px;
          span {
            display: block;
            padding-bottom: 20px;
            font-size: 0.8rem;
            color: $black;
            font-weight: normal;
            margin-left: 10px;
            width: calc(100% - 60px);
          }
          img {
            display: inline-block;
            position: relative;
            float: none;
            @include size(50px,auto);
          }
        }
        ins {
          text-decoration: none;
          * {
            color: $red;
            font-weight: bold;
          }
        }
      }
    }
  }
  .widget_recent_entries {
    ul {
      li {
        margin: 0;
        padding: 5px 0;
        a {
          font-size: 1rem;
          color: $black;
        }
        .post-date {
          display: inline-block;
        }
      }
    }
  }
}