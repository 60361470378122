.nw-page-banner{
  position: relative;
  img{
    display: block;
    width: 100%;
  }
  .kc_text_block{
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%);
    width: 100%;
  }
}

.kc_text_block.app-page-content{
  @extend %title;
}