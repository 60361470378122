.kc_text_block.app-home-content{
  @extend %title;
  h6{
    a{
      display: inline-block;
      padding: 18px 40px;
      border-radius: 3px;
      background-color: $main;
      color: #fff;
      font-size: rem(16);
      font-weight: normal;
      letter-spacing: rem(2);
      line-height: 1;
      text-decoration: none;
      @include t;
      @include phone{
        padding: 10px 30px;
      }
      &:hover{
        background-color: #fff;
        color: $main;
      }
    }
  }
  p{
    margin-bottom: rem(35);
    color: $black; 
    font-size: rem(16);
    line-height: rem(30);
    letter-spacing: rem(1.6);
  }
}

@include app(home-carousel){
  padding: 0;
  > .kc-row-container.kc-container{
    padding: 0;
    max-width: 100%;
  }
  .swiper-pagination{
    display: flex;
    justify-content: flex-start;
    bottom: 0;
    left: 0;
    padding-bottom: 30px;
    padding-left: 60px;
    @include pad{
      justify-content: center;
      padding: 20px
    }
    span.swiper-pagination-bullet{
      margin: 0 10px;
      opacity: 0.5;
      background-color: $main;
      @include size(14px);
      @include phone{
        margin: 0 5px;
        @include size(10px);
      }
      &.swiper-pagination-bullet-active{
        opacity: 1;
      }
    }
  }
  .swiper-slide{
    overflow: hidden;
    img{
      display: block;
      width: 100%;
    }
    .yt-iframe{
      position: relative;
      &:after{
        content: "";
        display: block;
        padding-bottom: 56.3%;
      }
    }
    iframe{
      width: 100%;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%,50%);
      @include size(101%,calc(100% + 115px));
    }
  }
}

@include app(home-information){
  background-color: $background;
  > .kc-container.kc-row-container{
    max-width: 890px;
  }
  .list-post-type{
    padding: 25px;
    margin-bottom: 50px;
    background-color: rgba(#fff,0.8);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    @include phone{
      margin-bottom: 30px;
      padding: 0;
    }
    .list-item{
      padding: 0;
      margin: 0;
      @include t;
      &:last-of-type{
        border-bottom: none;
      }
      @include phone{
        padding: 14px 0;
      }
      &:hover{
        background-color: rgba($background,0.67);
      }
      .post-content{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 30px;
        @include phone{
          padding: 0 20px;
          flex-direction: column-reverse;
        }
      }
      figure,.text{
        display: none;
      }
      h3,.kc-entry_meta{
        padding: 0;
      }
      .kc-entry_meta{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 34%;
        @include phone{
          margin-bottom: 5px;
          width: 100%;
          justify-content: flex-start;
        }
        i{
          display: none;
        }
        .date{
          display: block;
          position: relative;
          white-space: nowrap;
          &:before{
            content: "";
            display: block;
            @include size(3px);
            background-color: $black;
            border-radius: 50%;
            position: absolute;
            right: 100%;
            bottom: 50%;
            transform: translate(-15px,50%);
            @include phone{
              display: none;
            }
          }
        }
        a,time{
          font-size: rem(18);
          color: $black;
          font-weight: normal;
          letter-spacing: rem(2);
          line-height: 1;
          @include media(max,900){
            letter-spacing: 0;
            font-size: rem(16);
          }
        }
        .categories-links{
          display: block;
          padding: 9px 0;
          margin: 0;
          background-color: $main;
          border-radius: 3px;
          @include phone{
            padding: 5px 0;
            background: none;
          }
          a{
            display: inline-block;
            text-decoration: none;
            color: #fff;
            font-size: rem(16);
            padding: 0 20px;
            white-space: nowrap;
            @include media(max,900){
              font-size: rem(14);
            }
            @include phone{
              padding: 0;
              color: $main;
            }
          }
        }
      }
      h3{
        padding-left: 20px;
        width: 66%;
        @include phone{
          padding-left: 0;
          width: 100%;
        }
        a{
          display: block;
          padding-top: 14px;
          padding-bottom: 14px;
          padding-right: 20px;
          font-size: rem(18);
          font-weight: normal;
          color: $black;
          text-decoration: none;
          letter-spacing: rem(2);
          line-height: rem(30);
          position: relative;
          @include media(max,900){
            padding-left: 10px;
          }
          @include phone{
            padding: 0 20px 0 0;
            font-size: rem(16);
          }
          &:after{
            content: "";
            display: block;
            position: absolute;
            background-image: url(/wp-content/uploads/icon_information_arrow.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            right: 0px;
            bottom: 50%;
            transform: translateY(50%);
            @include size(6px,12px);
          }
        }
      }
    }
  }
}

@include app(home-about){
  padding: 0;
  > .kc-row-container.kc-container{
    padding: 0;
    max-width: 100%;
  }
  div.kc_row_inner{
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    background-color: $main;
    > div{
      padding: 0;
      height: 100%;
    }
    img{
      width: 100%;
    }
  }
  .owl-nav-arrow.owl-theme .owl-controls{
    margin: 0;
    .owl-buttons div{
      margin: 0;
      left: rem(30);
      opacity: 1;
      transform: translateY(-50%);
      @include size(rem(32));
      &:before{
        display: none;
      }
      &.owl-prev{
        background-image: url(/wp-content/uploads/icon_about_arrow01.svg);
      }
      &.owl-next{
        left: auto;
        right: rem(30);
        background-image: url(/wp-content/uploads/icon_about_arrow02.svg);
      }
    }
  }
  .kc_text_block.app-home-content{
    padding: 30px 90px;
    height: 100%;
    @include phone{
      padding: 30px 20px 40px;
    }
    h2,h4,p{
      color: #fff;
    }
    h6{
      a{
        background-color: #fff;
        color: $main;
        &:hover{
          background-color: $hover;
        }
      }
    }
  }
}

@include app(home-beautiful){
  .list-post-type{
    margin-left: -8px;
    width: calc(100% + 16px);
    position: relative;
  }
  .swiper-slide{
    padding: 0 8px;
    width: floor(calc(100% / 3) * 100) * 0.01;
    position: relative;
    overflow: hidden;
    @include pad{
      width: 50%;
    }
    @include phone{
      width: 100%;
    }
    .kc-entry_meta{
      display: none;
    }
    figure{
      margin: 0;
      img{
        display: block;
        width: 100%;
      }
    }
    h3{
      position: absolute;
      bottom: 0;
      left: 8px;
      pointer-events: none;
      width: calc(100% - 16px);
      @include t(0.8);
      a{
        padding: 0px rem(35);
        @include size(100%);
        font-size: rem(18);
        letter-spacing: rem(2);
        line-height: rem(60);
        color: #fff;
        font-weight: normal;
        background-color: rgba($main,0.8);
        @include lineClamp(1);
      }
    }
    .kc-read-more{
      position: absolute;
      top: 0;
      left: 8px;
      border: none;
      font-size: 0;
      border-radius: 0;
      background: none;
      @include size(calc(100% - 16px),100%);
    }
    .text{
      margin: 0;
      padding: 15px rem(28);
      position: absolute;
      bottom: 0;
      left: 8px;
      transform: translateY(100%);
      @include t(0.8);
      @include size(calc(100% - 16px),auto);
      background-color: rgba($main,0.8);
      color: #fff;
      font-size: rem(18);
      opacity: 0;
    }
    &:hover{
      h3{
        transform: translateY(100%);
        opacity: 0;
      }
      .text{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }.swiper-button-next,
  .swiper-button-prev{
    @include media(max,1279){
      display: none;
    }
  }
  .swiper-button-next{
    right: rem(-40);
  }
  .swiper-button-prev{
    left: rem(-40);
  }
  .swiper-pagination{
    margin-top: 15px;
    position: relative;
    bottom: 0;
    display: none;
    @include media(max,1279){
      display: block;
    }
  }
}

@include app(home-select){
  padding: 0;
  > .kc-container.kc-row-container{
    padding: 0;
    max-width: 100%;
  }
  .kc_row_inner{
    margin: 0;
    width: 100%;
    > div{
      margin: 0;
      padding: 0;
      position: relative;
    }
  }
  .kc_single_image{
    position: relative;
    a,img{
      display: block;
      position: absolute;
      right: 50%;
      bottom: 50%;
      transform: translate(50%,50%);
      @include size(100%);
    }
    &:after{
      content: "";
      display: block;
      padding-bottom: 70%;
    }
  }
  .kc_text_block{
    padding: 45px 20px 30px;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate(50%,50%);
    z-index: 2;
    @include size(400px,auto);
    max-width: 90%;
    border-radius: 3px;
    border: solid 1px $main;
    pointer-events: none;
    @include t;
    @include media(max,1000){
      padding: 30px 20px 10px;
    }
    h2 img{
      margin-bottom: 25px;
    }
  }
  .kc_row_inner > div:hover{
    .kc_text_block{
      background-color: rgba(#fff,0.6);
    }
  }
}