@include app(page-contact){
  background-color: $background;
  .kc-container.kc-row-container{
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
  .kc_row_inner{
    background-image: url(/wp-content/uploads/img-contact-bg.png);
    background-size: cover;
    position: relative;
    padding: 60px 0;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      pointer-events: none;
      background-color: rgba(#fff,.3);
    }
    .kc_row_inner {
      position: relative;
      z-index: 2;
      margin: 0 auto;
      width: 100%;
      float: none;
      padding: 25px 80px;
      max-width: 1100px;
      background-image: none;
      background-color: rgba($main,.88);
      @include clearfix;
      @media screen and (max-width: 1200px) {
        right: 0;
      }
      @media screen and (max-width: 1000px) {
        padding: 25px;
      }
    }
    .kc_text_block:not(.app-page-content){
      padding-top: 70px;
      @include pad{
        padding-top: 0;
        margin-bottom: 30px;
      }
      h3{
        padding-bottom: 10px;
        margin-bottom: 30px;
        width: fit-content;
        color: #fff;
        font-size: rem(30);
        font-weight: 500;
        letter-spacing: rem(3);
        border-bottom: solid 3px #fff;
      }
      p,a{
        font-size: rem(16);
        color: #fff;
      }
    }
  }
  .wpcf7 {
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 6px 12px #00000029;
    margin-bottom: -120px;
    @include pad{
      padding: 30px 20px;
    }
    .wpcf7-response-output{
      margin: 0;
      padding: 10px 20px;
      width: 100%;
      color: $black;
      text-align: center;
      font-weight: normal;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      .input-row {
        margin-bottom: 15px;
        width: 100%;
        &.c-6 {
          width: calc(50% - 10px);
          margin-right: 20px;
          &:nth-of-type(2n-1) {
            margin-right: 0;
          }
        }
        &.btn {
          box-shadow: none;
          margin: 0;
          padding: 0;
          .ajax-loader {
            display: none;
          }
          input {
            display: table;
            margin: 0 auto;
            padding: 10px 40px;
            border-radius: 4px;
            font-size: 16px;
            border: solid 1px $main;
            color: $main;
            background-color: #fff;
            font-weight: normal;
            @include t(.5s);
            &:hover {
              background-color: $main;
              color: #fff;
              opacity: 1;
            }
          }
        }
      }
      label {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        color: $black;
        font-weight: 300;
        @media screen and (max-width: 640px) {
          font-size: 14px;
        }
        & + span {
          display: block;
          input,
          select,
          textarea {
            margin: 0;
            padding: 10px 20px;
            width: 100%;
            border: 1px solid rgba($gray,.5);
            border-radius: 5px;
            font-size: rem(16);
            background-color: #fff;
            box-shadow: none;
            @media screen and (max-width: 640px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}