@include app(page-tabs){
  background-color: $background;
  .kc-container.kc-row-container{
    max-width: 100%;
    padding: 0;
  }
  .kc_wrapper.ui-tabs{
    .kc_tabs_nav{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 20px;
      margin-bottom: 20px;
      background: none;
      line-height: normal;
      min-height: 0;
      li{
        margin-right: -1px;
        margin-bottom: 10px;
        background: none;
        border-right: solid 1px $black!important;
        border-left: solid 1px $black!important;
        line-height: normal;
        a{
          display: block;
          padding: 4px 20px;
          font-size: rem(20);
          height: auto;
          line-height: normal;
          background: none;
          @include t;
          @include pad{
            font-size: rem(16);
          }
          @include phone{
            font-size: rem(14);
          }
        }
        &:hover,&.ui-tabs-active{
          a{
            background-color: $main;
            color: #fff;
          }
        }
      }
    }
  }
  .kc_tabs_nav~.kc_tab .kc_tab_content{
    @include clearfix;
    padding: 0;
  }
  .kc_tabs_nav~.kc_tab{
    background: none;
    &.kc-section-active{
      animation: nw-fade 1s both;
    }
  }
  .kc_text_block:not(.app-page-content){
    margin-bottom: 70px;
    &:last-child{
      margin-bottom: 0;
    }
    @include pad{
      margin-bottom: 40px;
    }
    h3{
      margin-bottom: 20px;
    }
    p,li{
      font-size: rem(16);
      line-height: rem(30);
      color: $black;
    }
    ul,ol{
      margin: 0;
      li{
        margin: 0;
      }
    }
    h6{
      a{
        display: inline-block;
        padding: 15px 30px 15px 32px;
        background-color: $main;
        font-size: rem(16);
        color: #fff;
        font-weight: normal;
        letter-spacing: 2px;
        position: relative;
        min-width: 180px;
        text-decoration: none;
        &:after{
          content: "";
          display: block;
          position: absolute;
          bottom: 50%;
          right: 50%;
          transform: translate(50%,50%);
          border: solid 1px #fff;
          pointer-events: none;
          line-height: 1;
          @include size(calc(100% - 10px));
        }
      }
    }
  }
  .kc_row_inner{
    float: none;
    max-width: 930px;
    margin: 0 auto;
    > div{
      padding: 0 45px;
      margin: 0 auto 70px;
      @include pad{
        margin-bottom: 40px;
      }
      @include phone{
        padding: 0 20px;
      }
    }
    .kc_single_image{
      margin-bottom: 70px;
      @include pad{
        margin-bottom: 40px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    &.app-tab-banner{
      margin: 0;
      width: 100%;
      max-width: 100%;
      position: relative;
      > div{
        width: 100%;
        padding: 0;
        margin: 0;
        position: static;
      }
      .kc_single_image{
        margin: 0;
        img{
          width: 100%;
          @include phone{
            height: 400px;
            max-width: none;
            object-fit: cover;
          }
        }
      }
      .kc_text_block{
        margin: 0;
        padding-left: 10%;
        position: absolute;
        bottom: 50%;
        left: 0%;
        width: 100%;
        @include pad{
          padding-left: 20px;
        }
        h3{
          font-size: rem(30);
          letter-spacing: rem(10);
          color: $main;
          @include phone{
            font-size: rem(25);
            font-weight: 500;
            letter-spacing: rem(5);
          }
          + p{
            margin-top: 15px;
            font-size: rem(25);
            font-weight: 300;
            letter-spacing: rem(3);
            color: $black;
            @include phone{
              font-size: rem(20);
              letter-spacing: rem(2);
            }
          }
        }
      }
      + .kc_text_block{
        display: block;
        padding: 35px 55px;
        width: calc(100% - 40px);
        margin: 0 auto;
        max-width: 1020px;
        background-color: rgba($main,0.85);
        float: none;
        color: #fff;
        position: relative;
        top: -90px;
        z-index: 2;
        @include pad{
          padding: 30px 20px;
          margin-bottom: -40px;
        }
        h3,p{
          color: #fff;
        }
      }
    }
  }
}

.kc_tabs{
  padding-top: 30px;
}