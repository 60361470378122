.category{
  #content{
    background-color: $background;
  }
  .breadcrumbs{
    display: none;
  }
  #content-inside{
    @include container;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 60px;
    max-width: 1000px;
    @include pad{
      padding-top: 30px;
    }
  }
  .post{
    display: flex;
    border: none!important;
    padding-top: 0;
    padding-bottom: rem(30);
    flex-wrap: wrap;
    .list-article-thumb img,
    .list-article-content{
      box-shadow: 0px 3px 6px #00000029;
    }
    .list-article-thumb{
      display: block;
      margin: 0;
      width: 40%;
      @include pad{
        width: 100%;
      }
      a,img{
        display: block;
        width: 100%;
      }
    }
    .list-article-content{
      display: block;
      margin-left: 20px;
      padding: 20px;
      width: calc(60% - 20px);
      background-color: #fff;
      position: relative;
      @include pad{
        width: 100%;
        margin-left: 0;
      }
      .list-article-meta{
        margin-bottom: 0;
        .date{
          display: flex;
          align-items: center;
          font-size: rem(14);
          color: $black;
          font-weight: normal;
          &:before{
            content: "";
            display: block;
            margin-right: 10px;
            background-image: url(/wp-content/uploads/icon_title01.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            @include size(20px,16px);
          }
        }
      }
      .entry-header{
        margin-bottom: 10px;
        h2{
          a{
            font-size: rem(20);
          }
        }
      }
      .entry-excerpt{
        padding-bottom: 40px;
        a.read-more{
          position: absolute;
          right: 20px;
          bottom: 20px;
          text-transform: capitalize;
          color: $main;
          padding-right: 20px;
          text-decoration: none;
          background-image: url(/wp-content/uploads/icon_information_arrow.svg);
          background-repeat: no-repeat;
          background-position: right center;
        }
      }
    }
  }
}

.wp-pagenavi{
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: rem(16);
  color: $black;

}

body.single-post{
  #content-inside{
    @include container;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 60px;
    max-width: 1000px;
    @include pad{
      padding-top: 30px;
    }
  } 
  .entry-header{
    .date{
      font-size: rem(20);
      color: $black;
    }
    h1{
      font-size: rem(30);
      color: $black;
      margin-bottom: 20px;
    }
  }
  .entry-content{
    color: $black;
    img{
      display: block;
      width: 100%;
    }
    p{
      font-size: rem(16);
      line-height: rem(30);
    }
  }
}

.post-btns{
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  width: calc(100% + 20px);
  padding-top: 30px;
  > div{
    width: 50%;
    padding: 0 10px;
    a{
      color: $black;
      font-size: rem(18);
      text-decoration: none;
      span{
        display: block;
        font-size: rem(16);
        color: $main;
      }
    }
    &.next{
      text-align: right;
    }
    &.prev{
      text-align: left;
    }
  }
}