@mixin media($type,$start,$end:0) {
  @if $type == "max" {
    @media screen and (max-width: #{$start}px) {
      @content;
    }
  }
  @if $type == "min" {
    @media screen and (min-width: #{$start}px) {
      @content;
    }
  }
  @if $type == "interval" {
    @media (min-width: #{$start}px) and (max-width: #{$end}px) {
      @content;
    }
  }
}
@mixin mobile($type:max) {
  @media screen and (#{$type}-width: 1140px) {
    @content;
  }
}
@mixin pad($type:max) {
  @media screen and (#{$type}-width: 767px) {
    @content;
  }
}
@mixin phone($type:max) {
  @media screen and (#{$type}-width: 640px) {
    @content;
  }
}
@mixin mini($type:max) {
  @media screen and (#{$type}-width: 480px) {
    @content;
  }
}
@mixin t($t:0.5) {
  transition-duration: #{$t}s;
}
@mixin size($w,$h:$w) {
  width: $w;
  height: $h;
}
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
// container
@mixin container {
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1500px;
  width: 95%;
  @content;
  @include mobile{
    width: 100%;
  }
}
// 指定icon
@mixin fontawesome {
  &:before {
    font-family: 'FontAwesome';
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    @content;
  }
}
// 客製化delay
@mixin delay($n:4,$cut:0.2) {
  @for $i from 0 through 100 {
    &:nth-of-type(#{$i+1}) {
      transition-delay: #{$i%$n*$cut}s;
    }
  }
}
// 按鈕初始樣式
@mixin button {
  padding: 10px;
  width: 200px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 1;
  background-color: $btnBg;
  color: $btnColor;
  font-weight: normal;
  @include pad {
    width: 150px;
  }
  @content;
}
// 限制行數(...)
@mixin lineClamp($n:2) {
  display: -webkit-box;
  -webkit-line-clamp: $n;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
// app
@mixin app($id:"",$class:"") {
  [id^="app-#{$id}"]#{$class}{
    @content;
  }
}

