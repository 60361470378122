.site-header {
  position: static;
  line-height: normal;
  background-color: rgba(#fff,.9);
  box-shadow: 0px 3px 6px #00000029;
  .header-right-wrapper {
    float: right;
  }
  .container {
    position: static;
  }
}


#header-section {
  position: relative;
  border: none;
  // top: 0;
  // left: 0;
  // width: 100%;
}

#site-navigation {
  @include clearfix;
}
.site-branding {
  padding: 9px 0;
  @include t(0.3);
  @include phone{
    padding: 0;
  }
  .site-text-logo {
    display: block;
    // background-image: url(/wp-content/uploads/default.png);
    background-image: url(/wp-content/uploads/header_logo.svg);
    // background-color: #333;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 0;
    @include size(142px,62px);
  }
}
      
.main-navigation{
  // position: relative
  margin-right: -25px;
  @include mobile { 
    // position: absolute;
    // top: 0;
    margin-right: 0;
    // left: 0;
    width: 100%;
  }
  .onepress-menu {
    display: flex;
      // background-color: #333
      // 左側滑入
    @include mobile {
      padding-bottom: 80px;
      flex-direction: column;
      border: none;
      overflow: auto;
      position: absolute;
      left: -275px;
      top: 0;
      height: 100vh;
      width: 100%;
      max-width: 275px;
      z-index: 3;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      float: none;
      a {
        display: block;
        font-size: rem(20);
        line-height: 30px;
        letter-spacing: rem(2.4);
        font-weight: normal;
        text-align: center;
        color: $black;
        @include mobile {
          text-align: left;
          border-bottom: solid 1px $border;
        }
      }
      &:hover,
      &.current-menu-item {
        > a {
          color: $main;
          font-weight: bold;
        }
      }
    }
    // 第一層
    > li {
      line-height: 51px;
      > a {
        padding: 25px;;
        @include mobile {
          padding: 10px 20px;
        }      
      }
      // 二層選單
      > .sub-menu {
        @include mobile(min) {
          left: calc(50% - 80px);
        }
        > li{
          > a{
            border-bottom: solid 1px $border
          }
          &:last-of-type{
            > a{
              border: none;
            }
          }
        }
      }
      // 最後項目第三層至左
      &:last-of-type,
      &.nw-third-left {
        .sub-menu {
          .sub-menu {
            @include mobile(min) {
              left: auto;
              right: 100%;
            }    
          }
        }
      }
    }
    // 子選單樣式
    .sub-menu {
      display: none;
      width: 100%;
      min-width: 160px;
      border: none;
      background-color: rgba($black,0.7);
      box-shadow: 2px 2px 8px rgba($black,0.5);
      @include mobile {
        // background-color: $background;
        position: static;
      }
      
      li {
        a {
          display: flex;
          justify-content: space-between;
          padding: 7px 15px;
          background-color: transparent;
          border-top: none;
          font-size: rem(16);
          text-align: left;
          color: #fff;
          font-weight: normal;
          @include mobile{
            padding: 10px 20px;
            border-bottom: solid 1px #fff;
          }
          &:hover{
            // background-color: $background;
            color: $hover;
          }
        }  
        &.menu-item-has-children>a:after{
          transform: rotate(-45deg);
          margin-top: 5px;
          border-color: #fff;
        }
      }
      .nav-toggle-subarrow{
        color: #fff;
      }
      .sub-menu{
        padding: 10px 0;
        width: fit-content;
        @include mobile{
          width: 100%;
        }
        > li{
          > a{
            padding: 3px 15px;
            font-size: rem(14);
            border: none;
          }
        }
      }
    }
    // 子選單
    li.menu-item-has-children {
      > a{
        display: flex;
        align-items: center;
        &:after{
          content: "";
          display: inline-block;
          margin-bottom: 4px;
          margin-left: 5px;
          border-bottom: solid 1px $black;
          border-right: solid 1px $black;
          transform: rotate(45deg);
          @include size(7px);
          @include mobile{
            display: none;
          }
        }
      }
      &:hover {
        @include mobile(min) {
          > .sub-menu {
            display: block;
            animation: fade 0.5s both;
          }
        }
      }
    }
    .nav-toggle-subarrow {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      cursor: pointer;
      border-left: solid 1px $border;
      @include size(51px);
      @include mobile {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include pad {
        @include size(51px);
      }
    }
    .nav-toggle-dropdown > .nav-toggle-subarrow {
      i {
        transform: rotateX(180deg);
      }
    }
  }
}

#nav-toggle {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 25px;
  z-index: 999;
  font-size: 0;
  @include size(35px);
  transform: translateY(-50%);
  span {
    border-radius: 50px;
    &:before,
    &:after {
      transform-origin: 50% 50%;
      border-radius: 50px;
    }
  }
  &.nav-is-visible {
    background-color: rgba(#fff,.3);
    border-radius: 50%;
    span {
      &:before,
      &:after {
        top: 0;
        left: 0;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

#nw-toggle-btn {
  display: none;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  font-size: 0;
  z-index: 999;
  @include t(0.8);
  @include size(35px);
  @include mobile {
    display: block;
  }
  span {
    display: block;
    margin: 16px auto;
    @include size(25px,3px);
    background-color: $black;
    position: relative;
    border-radius: 50px;
    @include t(.3);
    &:before,
    &:after {
      content: "";
      display: block;
      @include size(25px,3px);
      background-color: $black;
      transform-origin: 50% 50%;
      position: absolute;
      left: 0;
      @include t(.3);
      border-radius: 50px;
    }
    &:before {
      top: -8px;
    }
    &:after {
      top: 8px;
    }  
    // &.nw-active {
    //   right: 300px;
    //   span {
    //     background-color: transparent;
    //     &:before,
    //     &:after {
    //       top: 0;
    //       left: 0;
    //       background-color: #fff;
    //     }
    //     &:before {
    //       transform: rotate(45deg);
    //     }
    //     &:after {
    //       transform: rotate(-45deg);
    //     }
    //   }
    // }
  }
}

.float-right-buttons{
  position: fixed;
  right: 0px;
  top: 30%;
  z-index: 20;
  @include phone{
    transform: scale(0.7);
    transform-origin: right center;
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      margin: 0;
      padding: 0;
      list-style-type: none;
      background-color: rgba(86,15,100,.9);
      a{
        display: block;
        // font-size: 0;
        padding: 5px;
        overflow: hidden;
        color: #fff;
        text-align: center;
        width: 70px;
        font-size: rem(14);
        text-decoration: none;
        img{
          display: block;
          margin: 0 auto 2px;
          width: 40px;
          max-width: 100%;
        }
        &:hover{
          background-color: rgba(255,255,255,.15);
        }
      }
      &:nth-of-type(even){
        a{
          background-color: rgba(255,255,255,.15);
        }
      }
    }
  }
}