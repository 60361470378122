@include app(page-about){
  position: relative;
  background-image: url(/wp-content/uploads/img-about-banner-2.png);
  &:after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($main,0.8);
    pointer-events: none;
    @include size(100%);
  }
  .kc-container.kc-row-container{
    position: relative;
    z-index: 2;
    max-width: 710px;
  }
  .kc_text_block{
    @extend %pagecontent;
    p{
      color: #fff;
    }
    &.app-page-content{
      h2,h4{
        color: #fff;
      }
    }
  }
}

@include app(page-team){
  .kc_row_inner{
    align-items: center;
    margin: 0;
    margin-bottom: rem(30);
    width: 100%;
    > div{
      padding: 0;
    }
    > div:last-of-type{
      padding: 110px 50px 50px;
      background-color: rgba($main,0.6);
      @include pad{
        padding: 70px 20px 20px;
      }
    }
    .kc_single_image{
      img{
        display: block;
        object-fit: cover;
        max-width: none;
        width: calc(100% + 100px);
        @include pad{
          max-width: 100%;
        }
      }
    }
    .kc_text_block{
      padding: 30px;
      background-color: $background;
      position: relative;
      h3{
        margin-bottom: 20px;
        margin-left: 10px;
        color: #fff;
        font-size: rem(30);
        letter-spacing: 2px;
        position: absolute;
        bottom: 100%;
        left: 0;
      }
      h4{
        margin-bottom: 10px;
        font-size: rem(18);
        font-weight: 500;
        letter-spacing: rem(2);
      }
      ul{
        margin: 0 0 20px;
        padding: 0;
        li{
          color: $black;
          margin: 0 0 0 15px;
        }
      }
    }
    &.nw-flex-row-reverse{
      .kc_single_image{
        img{
          margin-left: -100px;
          @include pad{
            margin-left: 0;
          }
        }
      }
    }
  }
}