@keyframes nw-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes nw-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes nw-load-dot {
  0% {    // transform: translateY(0)
    // height: 5px
    transform: scale(1);
  }
  100% {    // transform: translateY(-20px)
    // height: 15px
    transform: scale(0.5);
  }
}

@keyframes nw-load-dot-end {
  0% {    // transform: translateY(0)
    // height: 5px
    transform: scale(0.5);
    opacity: 1;
  }
  100% {    // transform: translateY(-20px)
    // height: 15px
    transform: scale(100);
    opacity: 0;
  }
};