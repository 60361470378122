// footer
.footer-widgets,
.site-footer {
  background: transparent;
  .widget-area {
    .widget {
      margin: 0;
    }
  }
}
#footer-widgets {
  // border-top: 3px $black solid;
  // padding: 32px 0 0;
  background-color: $main;
  padding: 40px 0;
  a{
    color: #fff;
    &:hover{
      color: #fff;
    }
  }
  .footer-widget.widget_nav_menu {
    padding: 0;
    margin: 0;
    @include container;
    .menu {
      display: flex;
      flex-wrap: wrap;
      padding: 0 90px;
      margin-bottom: 35px;
      justify-content: space-between;
      @include media(max,900) {
        padding: 0;
      }
      @include pad {
        margin-bottom: 15px;
      }
      &:after {
        display: none;
      }
      li {
        margin: 0;
        a {
          display: block;
          color: $black;
          text-decoration: none;
          font-size: 0.875rem;
          line-height: normal;
        }        // customer
        &.pll-parent-menu-item {
          display: none;
        }
        &.nw-only-submenu {
          > a {
            display: none;
          }
          .sub-menu {
            padding: 0;
          }      // 第一層
        }
      }
      > li {
        @include phone {
          width: 100%;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px $border;
        }
        &:last-of-type {
          border-bottom: none;
        }
        > a {
          margin-bottom: 10px;
          font-weight: bold;
          @include phone {
            margin-bottom: 0;
          }      // 子選單
        }
      }
      .sub-menu {
        @include phone {
          display: flex;
          flex-wrap: wrap;
          padding-top: 6px;
          margin-bottom: -5px;
        }
        li {
          margin-bottom: 8px;
          @include phone {
            width: 50%;
          }  // 隱藏
        }
      }
    }
  }
}

.app-footer-info{
  .flex{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .info{
    width: 48%;
    @include pad{
      margin-bottom: 40px;
      width: 100%;
    }
    h1.logo{
      width: 165px;
      img{
        display: block;
        width: 100%;
      }
    }
    ul{
      margin: 0 0 20px;
      padding: 0;
      li{
        color: #fff;
        margin: 0;
        font-size: rem(16);
        line-height: rem(28);
        letter-spacing: rem(3);
        @include pad{
          letter-spacing: 1px;
        }
      }
    }
    p{
      font-size: rem(14);
      line-height: rem(24);
      letter-spacing: 1px;
      color: #fff;
    }
  }
  .copyright{
    width: 48%;
    @include clearfix;
    @include pad{
      width: 100%;
    }
    .fb-page{
      width: 100%;
      @include clearfix;
      @include pad{
        display: block;
        float: none;
        margin: 0 auto;
      }
      span{
        float: right;
        width: 100%;
      }
    }
    p{
      margin-top: 10px;
      text-align: right;
      color: #fff;
      font-size: rem(14);
      @include pad{
        float: none;
        text-align: center;
      }
      a{
        color: #fff;
      }
    }
  }
}